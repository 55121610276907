<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('module.pic') }}</h5>
        </div>
        <form @submit.prevent="submitData()">
          <div class="modal-body">
            <input
              type="hidden"
              class="form-control"
              v-model.number="pic.id"
            />
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.name') }}:</label>
              <div class="col-lg-6">
                <input
                  type="text"
                  class="form-control"
                  v-model="pic.fullname"
                  v-validate="'required'"
                  data-vv-as="Nama"
                  name="fullname"
                  :class="{'is-invalid': errors.has('fullname') }"
                />
                <div
                  v-show="errors.first('fullname')"
                  class="invalid-feedback"
                >{{ errors.first('fullname') }}</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.tanggalLahir') }}:</label>
              <div class="col-lg-6">
                <date-picker
                  ref="datePickerVue"
                  :changedValue.sync="pic.dateOfBirth"
                  :id-date-picker="'pic_' + pic.id"
                  :picker-value="pic.dateOfBirth"
                  place-holder="DD/MM/YYYY"
                  :is-validate="true"
                  start-date
                  end-date="-0d"
                ></date-picker>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-clean btn-bold btn-upper btn-font-md"
              @click="clearData()"
            >{{ $t('button.close') }}</button>
            <button
              type="submit"
              class="btn btn-default btn-bold btn-upper btn-font-md"
            >{{ $t('button.save') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "../../_general/DatePicker";
import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
const PicPharmacyRepository = RepositoryFactory.get("picPharmacy");
import PicPharmacy from "../../../model/pic-farmasi-model";
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      pic: new PicPharmacy()
    };
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  computed: {
    ...mapState('MasterStore', {
      modalName: state => state.modalName,
      dataEdit: state => state.dataEdit
    }),
  },
  methods: {
    ...mapActions({
      createNew: 'MasterStore/createNew',
      updateById: 'MasterStore/updateById'
    }),
    ...mapMutations({
      clearData: 'MasterStore/CLEAR_DATA'
    }),
    updateData: function () {
      if(this.dataEdit) {
        this.pic = Object.assign({}, this.dataEdit);
      }
    },
    submitData: function () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.pic.id) {
            this.updateById({ repository: PicPharmacyRepository.updatePicPharmacy, data: this.pic });
          } else {
            this.createNew({ repository: PicPharmacyRepository.createPicPharmacy, data: this.pic });
          }
          return;
        }
      });
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    this.updateData();
  }
};
</script>
